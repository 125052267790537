<template src="./Settings.html"></template>


<script>

import { mapGetters } from "vuex";
import {
  AFISHA_WORK_MODE,
  EVENT_STATUS,
  CAMERA_TYPE
} from "@/core/common";

import { QrcodeStream } from 'vue-qrcode-reader'




export default {

  name: "Settings",

  components: {QrcodeStream},


  data: () => ({

    camera: CAMERA_TYPE.AUTO,
    test_camera: false,
    scan_result: "-- КОД С QR --",

    event_statuses:[
      EVENT_STATUS.INET_OPEN,
      EVENT_STATUS.KASSA_OPEN,
      EVENT_STATUS.NEW,
    ],
    selected_event_status:[],

    size:{
      form_width: 350,
      scan_area_height: 150,
      margins: 2
    },

    work_mode: "",

    isEmulate: false,     // режим эмуляции билетов
    isComing:  false,     // только предстоящие
    isEntry:   false,     // только на вход

    bottom_menu_model: [1,2],
    bottom_menu_items:[
      {
        icon: 'mdi-menu',
        text: 'Вызов меню',
        link: '/a',
      },
      {
        icon: 'mdi-calendar-today',
        text: 'Сегодня',
        link: '/a',
      },
      {
        icon: 'mdi-home-outline',
        text: 'База билетов',
        link: '/a',
      },
      {
        icon: 'mdi-data-matrix-scan',
        text: 'Сканирование (модуль)',
        link: '/a',
      },
      {
        icon: 'mdi-camera',
        text: 'Сканирование (камера)',
        link: '/a',
      },
      {
        icon: 'mdi-chart-bar',
        text: 'Статистика',
        link: '/a',
      },
    ]

  }),

  created() {

    this.work_mode = this.getSettingsWorkMode;
    this.camera = this.getSettings.camera;
    this.selected_event_status = this.getSettings.event_status

    this.isEmulate = this.getSettings.isEmulate;
    this.isComing = this.getSettings.isComing;
    this.isEntry = this.getSettings.isEntry;

    this.size = JSON.parse(JSON.stringify(this.getSettings.size));
    //console.log();

  },

  computed: {
    CAMERA_TYPE() {
      return CAMERA_TYPE
    },

    C_afisha_work_mode() {
      return AFISHA_WORK_MODE
    },

    C_event_status(){
      return EVENT_STATUS
    },

    ...mapGetters([
      "getSettings",
      "getSettingsWorkMode",
      "getProfileInfo"
    ]),
  },



  methods: {


    async onInit (promise) {
      try {
        const { capabilities } = await promise
        console.log(capabilities);
      }
      catch (e) {
        console.error(e)
        const triedFrontCamera = this.camera === 'front'
        const triedRearCamera = this.camera === 'rear'

        const cameraMissingError = e.name === 'OverconstrainedError'

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true
        }

      }
      finally {
        this.showScanConfirmation = this.camera === "off"
      }
    },

    async onDecode (content) {
      this.scan_result = content;

      // this.pause()
      // await this.timeout(500)
      // this.unpause()

    },

    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },



    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },





    logErrors(promise) {
      promise.catch(console.error)
    },



    onSettingsSave(){

      if (this.work_mode == AFISHA_WORK_MODE.AFISHA){
        this.isEmulate = true;
      }

      this.$store.dispatch("saveSettings", {
        work_mode:this.work_mode,
        camera: this.camera,
        size: this.size,
        event_status: this.selected_event_status,
        isEmulate:this.isEmulate,
        isComing:this.isComing,
        isEntry:this.isEntry
          });

    },

    onResetSettings() {
      this.$store.dispatch("resetSettings");
      window.location.reload();
    }


  }

}


</script>

<style scoped>

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.gold-text {
  color: #ffcf9f !important;
}

.gold-bg {
  background-color: #ffcf9f !important;
}

.v-expansion-panel-header
{
  color: #ffcf9f !important;
}



</style>

